<template>
  <div>
    <!--导航栏--->
    <div> <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" /></div>

    <van-field v-model="form.userName" center label="收货人" placeholder="请填写收货人姓名" input-align="right"
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);" />
    <van-field v-model="form.telNum" center type="tel" label="手机号码" placeholder="请填写收货人手机号" input-align="right"
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);" />
    <van-field input-align="right" center readonly clickable label="所在地区" :value="areaText" placeholder="请选择所在地区"
      @click="showPicker = true" style="border-bottom:1px solid rgba(0, 0, 0, 0.1);" />
    <van-popup v-model="showPicker" round position="bottom">
      <van-area title="请选择所在地区" :area-list="areaList" @cancel="showPicker = false" @confirm="onConfirm" />
    </van-popup>

    <van-field v-model="form.detailInfo" rows="1" autosize label="详细地址" type="textarea" placeholder="街道，楼牌号等"
      style="border-bottom:1px solid rgba(0, 0, 0, 0.1);" input-align="right" center />

    <van-cell title="设置默认地址" center label="提醒，每次下单会默认推荐使用该地址">
      <template #right-icon>
        <van-switch v-model="checked" size="12px" active-color="#39C15B" inactive-color="#dcdee0" @change="isDefault" />
      </template>
    </van-cell>

    <!-- 底部 -->
    <div @click="save">
      <van-goods-action>
        <van-goods-action-button type="primary" text="保存" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import { addAddress, editAddress } from '@/api/shopAddAddress/shopAddAddress'
import { areaList } from '@vant/area-data';
import { Toast } from "vant";

export default {
  data() {
    return {
      form: {
        userName: '',
        telNum: '',
        provinceName: '',
        cityName: '',
        countyName: '',
        detailInfo: '',
        isDefault: 0,
        delFlag: 0,
        createTime: '2021-07-12 00:00:00',
        updateTime: '2021-07-12 00:00:00'
      },
      areaText: '',
      areaList,
      showPicker: false,
      checked: false,
      title: '新建收货地址'
    };
  },
  created() { },
  mounted() {
    console.log(this.$route)
    if (this.$route.query.address != null) {
      this.form = JSON.parse(this.$route.query.address);
      this.areaText = this.form.provinceName + this.form.cityName + this.form.countyName;
      this.checked = this.form.isDefault == 1 ? true : false;
      this.title = '编辑收货地址';
      console.log(this.form)
    }
  },

  methods: {
    // 返回原生
    onClickLeft() {
      this.$router.back(-1)
    },
    onConfirm(value) {
      this.form.provinceName = value[0].name;
      this.form.cityName = value[1].name;
      this.form.countyName = value[2].name;
      this.areaText = value[0].name + value[1].name + value[2].name;
      this.showPicker = false;
    },
    isDefault() {
      if (this.checked) {
        this.form.isDefault = 1;
      }
      else {
        this.form.isDefault = 0;
      }
    },
    save() {
      if (this.form.userName == '') {
        Toast('请填写收货人姓名');
        return;
      }
      if (this.form.telNum == '') {
        Toast('请填写收货人手机号');
        return;
      }
      if (this.form.telNum.length != 11) {
        Toast('请输入11位手机号码');
        return;
      }
      if (this.form.provinceName == '') {
        Toast('请选择所在地区');
        return;
      }
      if (this.form.detailInfo == '') {
        Toast('请填写详细地址');
        return;
      }
      console.log(this.form)
      if (this.title == '新建收货地址')
        this.addAddress();
      else
        this.editAddress();
    },
    addAddress() {
      addAddress(this.form).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          Toast('添加成功');
          this.$router.back(-1);
        }
        else {
          Toast('添加失败');
        }
      });
    },
    editAddress() {
      editAddress(this.form).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          Toast('编辑成功');
          this.$router.back(-1);
        }
        else {
          Toast('编辑失败');
        }
      });
    }
  },
  watch: {},
  computed: {},
};
</script>

<style lang="less" scoped>
/deep/ .van-goods-action-button {
  background-color: red;
}
</style>